<template>
  <div
    @dragenter.stop.prevent="dragenter"
    @dragleave.stop.prevent="dragleave"
    @dragover.stop.prevent="dragover"
    class="objToMp4"
  >
    <div class="transition" v-if="showSchedule">
      <div class="title">OBJ 转 MP4</div>
      <div style="margin: 28px 0 5px">
        Convert your OBJ files to Customized Mp4 format which is compact,
        streamable and editable in Unity and UE (plugin support).
      </div>
      <div>使用前请仔细阅读注意事项</div>
      <el-button @click="uploadEvent" class="uploadButton"
        >选择文件夹</el-button
      >
      <div>或者将整个文件夹托拖动到这里</div>
    </div>
    <schedule v-else />
    <div class="residue">
      <span>剩余可用帧数：300 帧</span>
      <el-button icon="el-icon-refresh" class="refresh"></el-button>
      <el-button class="buy">购买流量</el-button>
    </div>
    <attention />
    <div class="masklayer" ref="masklayer" @drop.stop.prevent="drop">
      <span class="box">请将要上传的文件拖入到此处</span>
    </div>
    <input
      title="点击选择文件夹"
      ref="upload"
      @change="fileChange"
      id="h5Input2"
      multiple
      webkitdirectory
      accept="*/*"
      type="file"
      hidden
      name="html5uploader"
    />
  </div>
</template>
<script>
import AWS from "aws-sdk";
import { uuid } from "vue-uuid";
import attention from "./components/attention";
import schedule from "./components/schedule.vue";
import { getSTS } from '@/api/objToMp4'
export default {
  name: "objToMp4",
  data() {
    return {
      dom: "",
      s3: null,
      upload: "", 
      randomName: "", //随机名称
      showSchedule: 'ture',
      STS: '', // 桶密钥
      sumProgress: 0, // 总进度
      currentProgress: 0, //当前进度
      progressPercentage: 0 //进度百分比
    };
  },
  components: {
    attention,
    schedule,
  },
  created() {},
  mounted() {
    this.getSTS()
    AWS.config.update({
      accessKeyId: "AKIASLRDL7QKBYOYRB4N",
      secretAccessKey: "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",
      region: "cn-northwest-1"
    });
    //获取s3实例并指定桶名称。
    this.s3 = new AWS.S3({
      params: { Bucket: "pm-obj-to-mp4" },
    });
  },
  methods: {
    // 获取STS
    async getSTS() {
      const { data } = await getSTS()
      this.STS = data
      
    },
    //文件在元素上移动触发事件
    dragover() {},
    //鼠标松开触发事件
    drop(e) {
      let items = e.dataTransfer.items;
      for (let i = 0; i <= items.length - 1; i++) {
        //  DataTransferItem 对象
        let item = items[i];
        if (item.kind === "file") {
          let entry = item.webkitGetAsEntry();
          this.getFileFromEntryRecursively(entry);
        }
      }
      this.$refs.masklayer.style.display = "none";
    },
    // 判断文件是文件夹还是文件
    getFileFromEntryRecursively(entry) {
      if (entry.isFile) {
        console.log(123);
      } else {
        let reader = entry.createReader();
        reader.readEntries(
          (entries) => {
            entries.forEach((entry) => this.getFileFromEntryRecursively(entry));
            console.log(entries);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    //文件进入元素事件
    dragenter(e) {
      this.dom = e.target; //保存元素节点。
      this.$refs.masklayer.style.display = "block";
    },
    //文件离开元素事件,这个事件会触发多次。
    dragleave(e) {
      if (this.dom === e.target) {
        //判断离开的是否是相同的元素节点。
        this.$refs.masklayer.style.display = "none";
      }
    },
    //上传事件
    uploadEvent() {
      this.upload = this.$refs.upload;
      this.upload.click();
    },
    //input表单文件发生改变时触发
    fileChange() {
      this.showSchedule = false;
      this.sum(this.upload.files)
      if (this.upload.files.length !== "") {
        this.randomName = uuid.v4();
        const names = this.upload.files[0].webkitRelativePath;
        const name = names.split("/")[0];
        for (var i = 0; i < this.upload.files.length; i++) {
          //上传
          this.uploads(this.upload.files[i], i);
        }
      }
    },
    // 总进度 
    sum (file) {
      Object.values(file).forEach((item)=>{
        this.sumProgress += (item.size-0)
      })
    },
    //计算进度条
    countProgress () {
      this.progressPercentage = Math.round(this.currentProgress / this.progressPercentage) *100 + '%'
    },
    //上传文件
    uploads(formData, num) {
      let upload = this.s3.upload({
        Bucket: "pm-obj-to-mp4",
        Key: `username/${this.randomName}/${formData.name}`,
        Body: formData,
      });
      upload.send((err, data) => {
        if (err) {
          console.log(err);
        } else {
          this.currentProgress += (formData.size-0)
          // if (num == 276) console.log(+new Date());
          this.countProgress()
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.objToMp4 {
  padding-top: 153px;
  background-color: #252525;
  color: #fff;
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  .transition {
    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .uploadButton {
      color: #fff;
      font-size: 30px;
      background-color: #a73fff;
      border: 0px;
      width: 383px;
      height: 90px;
      border-radius: 9px;
      margin: 45px 0 16px;
    }
  }
  .drge {
    padding: 10px 0;
    font-size: 20px;
  }
  .masklayer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    border: 5px dashed #555;
    display: none;
    .box {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #555;
      font-size: 20px;
    }
  }
  .residue {
    margin: 60px 0 95px;
    .refresh {
      margin: 0 30px;
      width: 26px;
      height: 26px;
      color: #000;
      padding: 0;
      border-radius: 5px;
      background-color: #fff;
      ::v-deep .el-icon-refresh {
        font-weight: 700;
        font-size: 18px;
      }
    }
    .buy {
      background-color: #a73fff;
      color: #fff;
      border: 0;
      padding: 0;
      font-size: 16px;
      border-radius: 17px;
      width: 113px;
      height: 34px;
      line-height: 34px;
    }
  }
}
</style>
