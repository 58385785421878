import request from "@/utils/request"
/**
 * 获取用户信息
 */
export const userInfo = () => {
  return request({
    url: "/users/info",
  })
}
/**
* 获取账户余额
*/
export const getBalance = () => {
  return request({
    url: "/account/balance",
  })
}
/**
* 获取交易记录
*/
export const getTheTrade = () => {
  return request({
    url: "/account/records",
  })
}
/**
* 获取上传STS
*/
export const getSTS = () => {
  return request({
    url: "/sts",
  })
}
/**
* 生成MP4
*/
export const createMp4 = data => {
  return request({
    methods: 'POST',
    url: "/tasks",
    data
  })
}
/**
* 任务列表
*/
export const taskList = () => {
  return request({
    url: "/tasks",
  })
}