<template>
  <div class="uploadFile">
    <div class="progress">
      <div class="fileName">
        <div class="left"><i class="iconfont icon-wenjianjia"></i></div>
        <div>文件名</div>
      </div>
      <div class="fileProgress">
        <div v-if="newUpload">
          <el-progress
            :text-inside="true"
            :stroke-width="26"
            :percentage="70"
            color="#000000"
          ></el-progress>
          <div class="fileProgress-bottom">正在上传中 预计剩余时间：01：31</div>
        </div>
        <div v-else class="breakpoint">
          <div class="text">该文件历史记录中已上传 30% ，是否从该进度开始</div>
          <div>
            <el-button class="buttonUpload">继续上传</el-button>
            <el-button>重新上传</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="selectFrame">
      <div>文件中总帧数：1327</div>
      <div>
        <span>帧率: </span>
      </div>
      <div>
        <span>分辨率</span>
      </div>
    </div>
    <div class="conversion">
      <el-button class="button1">开始转化</el-button>
      <el-button class="button2">取消</el-button>
      <div>任务完成后可在“个人中心-历史记录”中看到该条信息</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newUpload: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.uploadFile {
  margin: 0 auto;
  background-color: #fff;
  width: 841px;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  .progress {
    display: flex;
    .fileName {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 153px;
      width: 300px;
      border-radius: 10px;
      box-shadow: 2px 0px 5px rgba($color: #000000, $alpha: 0.1);
      .iconfont {
        font-size: 70px;
      }
    }
    .fileProgress {
      width: 100%;
      padding: 50px 80px 0;
      .fileProgress-bottom {
        margin-top: 25px;
      }
      .breakpoint {
        .text {
          margin-bottom: 22px;
        }
        .el-button {
          width: 100px;
          height: 30px;
          background: #434343;
          color: #fff;
          border-radius: 30px;
          padding: 0;
        }
        .buttonUpload {
          margin-right: 15px;
        }
      }
    }
  }
  .selectFrame {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 25px 0;
    background: #f3f3f3;
  }
  .conversion {
    padding: 25px 0;
    .button1 {
      background-color: #ffc322;
      margin-right: 38px;
      width: 150px;
      height: 45px;
      border-radius: 45px;
      color: #fff;
      font-size: 18px;
    }
    .button2 {
      color: #fff;
      font-size: 18px;
      background-color: #999999;
      width: 150px;
      height: 45px;
      border-radius: 45px;
      margin-bottom: 10px;
    }
  }
}
</style>
