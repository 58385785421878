<template>
  <div class='attention'>
    <div class="title">注意事项</div>
    <div class="matter">
      <p>1.上传文件格式要求：</p>
      <p>2.生成的MP4素材可以使用“CatchLightPlugin”插件进行后续编辑、开发<el-button type="text" class="jump">详情点击跳转</el-button></p>
      <p>3.完成的任务以及产生的订单信息，可以在个人页面-历史订单 中找到记录<el-button type="text" class="jump">详情点击跳转</el-button></p>
      <p>4.在使用中您遇到的任何问题/不满，请联系我们的工作人员<el-button type="text" class="jump">详情点击跳转</el-button></p>
    </div>
    <div class="banner">
      <el-image src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg" fit="fill"></el-image>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style lang='scss' scoped>
  .attention {
    padding: 68px 0 50px;
    background: #15131b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 24px;
      margin-bottom: 38px;
      font-weight: bold;
    }
    .matter {
      text-align:initial;
      .jump {
        margin-left: 20px;
        padding: 0; 
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-decoration: underline;
        color: #FFC322;
      }
    }
    .banner {
      width: 976px;
      height: 170px;
      // overflow: hidden;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>